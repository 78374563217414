import AboutCard1 from './AboutCard1';
import AboutCard2 from './AboutCard2';
import AboutCard3 from './AboutCard3';
import AboutCard4 from './AboutCard4';
import AboutCard5 from './AboutCard5';
import AboutCard6 from './AboutCard6';
import AboutCard7 from './AboutCard7';
import AboutCard8 from './AboutCard8';
import AboutCard9 from './AboutCard9';

import './About.css'
import AboutCard10 from './AboutCard10';

function About() {

  return (
    <div className='about_page page_padding'>
      <div className="container">
        <div className="sc_title_box">
          <h1 className="sc_title">about me</h1>
          <h2 className="sc_sub_title">about me</h2>
        </div>
        <div className="contents_wrapper">
          <AboutCard1 />
          <AboutCard2 />
          <AboutCard10 />
          <AboutCard3 />
          <AboutCard4 />
          <AboutCard5 />
          <AboutCard6 />
          <AboutCard7 />
          <AboutCard8 />
          <AboutCard9 />
        </div>
      </div>
    </div>
  )
}

export default About