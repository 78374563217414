import abtImg7 from '../../../assets/images/abt-img-7.png'

function AboutCard7() {
    return (
        <div className='grid_box right_image'>
            <div className="desc_box">
                <p>My unique experiences, from sailing with my father to working in shipping, marine claims management, and law, have prepared me to effectively advocate for our shipping industry as a whole. I am honored to have recently been appointed as a Member of the Standing Committee on Blue Economy and the Standing Committee on Industry/Academia at the Dhaka Chamber of Commerce & Industry (DCCI).</p>
            </div>
            <div className="img_box">
                <img src={abtImg7} alt="thumb" className='w_100' />
            </div>
        </div>
    )
}

export default AboutCard7