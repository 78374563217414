"use client";

import Gallery from "./components/Gallery";
import { useEffect, useState } from "react";
import Headline from "./components/Headline/Headline";
import axios from "axios";
import { GALLERY_API } from "../../../Utilities/APIs/APIs";

const GalleryPage = () => {
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(GALLERY_API);

      console.log(data);
      setGalleryData(data);
    }

    fetchData();
  }, []);
  return (
    <div
      style={{ paddingTop: "100px", minHeight: "100vh" }}
      className="container"
    >
      <div className="sc_title_box">
        <h1 className="sc_title">Gallery</h1>
        <h2 className="sc_sub_title">Gallery</h2>
      </div>
      <Gallery galleryData={galleryData} />
    </div>
  );
};

export default GalleryPage;
