import React from "react";
import { FaArrowRight } from "react-icons/fa6";

export default function GalleryItemInfo({ item }) {
  return (
    <div>
      <div className={`${item?.cardImage ? "gallery_overlay" : ""}`}>
        <h2 className="title" style={{ lineHeight: "140%" }}>
          {item?.title}
        </h2>
        <FaArrowRight
          style={{ fontSize: "3rem", color: "#fff", alignSelf: "end" }}
        />
      </div>
    </div>
  );
}
