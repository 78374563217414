import abtImg8 from '../../../assets/images/abt-img-8.png'

function AboutCard8() {
    return (
        <div className='grid_box left_image'>
            <div className="img_box">
                <img src={abtImg8} alt="thumb" className='w_100' />
            </div>
            <div className="desc_box">
                <p>Alongside my professional pursuits, I am an avid soccer lover and actively participate in Corporate Soccer Tournaments such as the Ascent Cup and the Corporate Football Fiesta in Dhaka. Additionally, I feel privileged to be a part of various philanthropic and social initiatives such as JAAGO, JCI, Lions Club and the House of Volunteers. Recently, I partnered with the project "TaalGoal," a football tournament for slum kids organized by the Global Shapers Dhaka Hub – an initiative of the World Economic Forum. The mission of the project is to provide an alternative source of recreation to underprivileged kids (both male and female) and utilize sports as a tool to reduce crime and drug abuse among the most underprivileged children of Dhaka, offering them a platform to enjoy their childhood.</p>
                <br/>
                <p>
                    I have most recently been appointed as a Steering Committee Member of the YoungShip International IMO (International Maritime Organisation) Working Group. YoungShip is a professional non-profit organization dedicated to the growth and development of young people working within the global maritime industry. With over 5000 individual members worldwide and operations spanning over 30 countries countries, YoungShip aims to promote the voice of young professionals in the sector and empower them to have a positive impact in the sector.
                </p>
            </div>
        </div>
    )
}

export default AboutCard8