import { NavLink } from "react-router-dom";

import "./MenuLg.css";

function MenuLg() {
  return (
    <nav>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/about">about</NavLink>
      <NavLink to="/blogs">blogs</NavLink>
      <NavLink to="/gallery">Gallery</NavLink>
    </nav>
  );
}

export default MenuLg;
