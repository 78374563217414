import { NavLink } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";
import Logo from "../../../Elements/Logo/Logo";
import blackLogo from "../../../../assets/images/logo-black.svg";

import "./MenuSm.css";

function MenuSm({ handleClick, showMenu }) {
  return (
    <>
      <div className={`menu_sm ${showMenu && "show"}`}>
        <button className="btn_menu_close" onClick={handleClick}>
          <RiCloseLine />
        </button>
        <div className="logo_wrapper" onClick={handleClick}>
          <Logo imgSrc={blackLogo} />
        </div>
        <div className="menu_sm_links">
          <NavLink to="/" onClick={handleClick}>
            Home
          </NavLink>
          <NavLink to="/about" onClick={handleClick}>
            about me
          </NavLink>
          <NavLink to="/blogs" onClick={handleClick}>
            blogs
          </NavLink>
          <NavLink to="/gallery" onClick={handleClick}>
            Gallery
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default MenuSm;
