import { Link } from "react-router-dom";

function Logo({ imgSrc, textWite }) {
  return (
    <Link to="/">
      {/* <img
        className="w_100"
        src={imgSrc}
        alt="logo"
        style={{ mixBlendMode: "multiply" }}
      /> */}
      <h3
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "1.8rem",
          fontWeight: "700",
          gap: "0.4rem",
          color: textWite || "#000",
        }}
      >
        Tanjil <span style={{ color: "var(--blue)" }}>Ahmed</span> Ruhullah
      </h3>
    </Link>
  );
}

export default Logo;
