import { useEffect, useState } from 'react';
import axios from 'axios';
import { BLOGS_API, IMAGE_URL } from '../../../Utilities/APIs/APIs'

import BlogCard from '../../Layouts/BlogCard/BlogCard'

import './Blogs.css'

function Blogs() {

    const [blogs, setBlogs] = useState(null);

    useEffect(() => {
        async function getBlogs() {
            const { data } = await axios.get(BLOGS_API)
            setBlogs(data)
        }

        getBlogs()
    }, [])

    return (
        <section className="blog_page page_padding">
            <div className="container">
                <div className="sc_title_box">
                    <h1 className="sc_title">All Blogs</h1>
                    <h2 className="sc_sub_title">All Blogs</h2>
                </div>
                <div className="grid_box">
                    {blogs?.map(blog => (
                        <BlogCard
                            key={blog._id}
                            imgUrl={IMAGE_URL + blog?.image}
                            title={blog?.name}
                            link={'/single-blog/' + blog?._id}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Blogs