"use client";

import GalleryItemContainer from "./GalleryItemContainer/GalleryItemContainer";

import "./gallary.css";

const Gallery = ({ galleryData }) => {
  return (
    <div style={{ marginTop: "25px" }}>
      <div className="gallery_wrapper">
        <GalleryItemContainer galleryData={galleryData} />
      </div>
    </div>
  );
};

export default Gallery;
