import { Link } from 'react-router-dom';
import { RiArrowRightLine } from 'react-icons/ri';
// import homeAboutImg from '../../../../assets/images/abt-img-3.jpg'

import './AboutMe.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ABOUT_ME_API } from '../../../../Utilities/APIs/APIs';

function AboutMe() {
  const [data, setData] = useState();
  useEffect(() => {
    async function fetchAboutMe() {
      const { data } = await axios.get(ABOUT_ME_API);
      setData(data[0]);
    }
    fetchAboutMe();
  }, [data]);

  return (
    <section className='about_me'>
      <div className='container'>
        <div className='grid_box'>
          {/* <div className="image_box">
                        <img className='w_100' src={homeAboutImg} alt="thumb" />
                    </div> */}
          <div className='content_box'>
            <div className='sc_title_box'>
              <h1 className='sc_title'>about</h1>
              <h2 className='sc_sub_title'>Tanjil Ahmed Ruhullah</h2>
            </div>
            <div className='desc_box'>
              <p
                className='desc'
                dangerouslySetInnerHTML={{ __html: data?.description }}
                style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}
              />
            </div>
            <Link className='btn_more' to='/about'>
              <span>Know More</span>
              <p>
                <RiArrowRightLine />
              </p>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
