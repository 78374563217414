import { Link } from 'react-router-dom'

import './BlogCard.css'

function BlogCard({ imgUrl, title, link }) {
  return (
    <div className='blog_card'>
      <Link to={link}>
        <div className="img_box">
          <img className='w_100' src={imgUrl} alt="thumb" />
        </div>
        <h4 className='capitalize'>{title}</h4>
      </Link>
    </div>
  )
}

export default BlogCard