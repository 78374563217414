import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { BLOGS_API, IMAGE_URL } from '../../../Utilities/APIs/APIs';

import './SingleBlog.css'

function SingleBlog() {

  const { id } = useParams();

  const [singleBlog, setSingleBlog] = useState(null);

  useEffect(() => {
    async function getSingleBlogs() {
      const { data } = await axios.get(BLOGS_API + id)
      setSingleBlog(data)
    }

    getSingleBlogs()
  }, [id])

  return (
    <div className='single_blog page_padding'>
      <div className="container">
        <div className="sc_title_box">
          <h2 className="sc_sub_title">{singleBlog?.name}</h2>
        </div>
        <div className="img_box">
          <img src={IMAGE_URL + singleBlog?.image} alt="thumb" />
        </div>
        <div
          className="desc_box"
          dangerouslySetInnerHTML={{ __html: singleBlog?.description }}
        >
        </div>
      </div>
    </div>
  )
}

export default SingleBlog