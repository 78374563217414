import abtImg5 from '../../../assets/images/abt-img-5.png'

function AboutCard5() {
    return (
        <div className='grid_box right_image'>
            <div className="desc_box">
                <p>In addition to my involvement with CILT, my mother, who is my mentor, plays a significant role in the strategic management of our business. She is also the current Treasurer of WiLAT (Women in Logistics and Transport) Bangladesh, a special forum of CILT that provides a platform for women engaged in the supply chain, logistics, and transport industry to network and access opportunities for career advancement, aiming to become leading professionals in the field.</p>
            </div>
            <div className="img_box">
                <img src={abtImg5} alt="thumb" className='w_100' />
            </div>
        </div>
    )
}

export default AboutCard5