import { useEffect, useState } from 'react'
import axios from 'axios'
import { FEATURE_BLOGS_API, IMAGE_URL } from '../../../../Utilities/APIs/APIs'
import BlogCard from '../../../Layouts/BlogCard/BlogCard'

import './HomeBlog.css'
import { Link } from 'react-router-dom'
import { RiArrowRightLine } from 'react-icons/ri'

function HomeBlog() {

    const [featureBlogs, setFeatureBlogs] = useState(null);

    useEffect(() => {
        async function functionName() {
            const { data } = await axios.get(FEATURE_BLOGS_API)
            setFeatureBlogs(data)
        }

        functionName()
    }, [])


    return (
        <section className="home_blog">
            <div className="container">
                <div className="sc_title_box">
                    <h1 className="sc_title">Blogs</h1>
                    <h2 className='sc_sub_title'>Blogs</h2>
                    <Link className='btn_more' to='/blogs'>
                        <span>view all</span>
                        <p><RiArrowRightLine /></p>
                    </Link>
                </div>
                <div className="grid_box">
                    {featureBlogs?.map(featureBlog => (
                        <BlogCard
                            key={featureBlog._id}
                            imgUrl={IMAGE_URL + featureBlog?.blog?.image}
                            title={featureBlog?.blog?.name}
                            link={'/single-blog/' + featureBlog?.blog?._id}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default HomeBlog