import abtImg1 from '../../../assets/images/abt-img-2.png'

function AboutCard2() {
  return (
    <div className='grid_box right_image'>
      <div className="desc_box">
        <p>Although I graduated with a BSc in Electronics & Communications Engineering from BRAC University, I chose to embark on a career in the Maritime & Shipping Industry when my father presented me with an opportunity to work for his venture, Interport Group.</p>

        <br />
        <p>My father sacrificed his lucrative sailing career and came back ashore to support my mother in raising me and my brother more closely. I felt the least I could do was stay beside him and support him to the best of my capacity in the business. However, the decision wasn't a difficult one, as my father has been a role model for me since my childhood. Staying close to him also meant that I could learn more quickly from his vast experience, knowledge, wisdom, and values.</p>
      </div>
      <div className="img_box">
        <img src={abtImg1} alt="thumb" className='w_100' />
      </div>
    </div>
  )
}

export default AboutCard2