"use client";

import { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Gallery from "./Gallery";
import axios from "axios";

function GalleryDetailsCard({ gallery, singleGallery }) {
  const [titles, setTitles] = useState("");
  const [descriptions, setDescription] = useState("");
  const navigate = useNavigate();

  return (
    <div
      style={{ marginTop: "8rem", minHeight: "100vh" }}
      className="container"
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate(-1)}
      >
        <IoArrowBack className="text-base" />
        <p className="text-base font-normal">Back to Gallery</p>
      </div>
      <h1
        className="text-[32px] text-[#020202] font-semibold text-center pb-2"
        style={{
          fontSize: "3.6rem",
          color: "var(--blue)",
          fontWeight: "700",
          marginBottom: "1rem",
          marginTop: "3rem",
        }}
      >
        {singleGallery?.title}
      </h1>
      <p
        className="text-secondary mx-auto pb-7"
        style={{
          fontSize: "1.6rem",
          color: "#000",
          // fontWeight: "500",
          marginTop: "2.5rem",
          // marginBottom: "2.5rem",
        }}
      >
        {singleGallery?.description}
      </p>

      <Gallery galleryData={gallery} />
    </div>
  );
}

export default GalleryDetailsCard;
