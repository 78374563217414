import abtImg9 from "../../../assets/images/abt-img-9.png";

function AboutCard9() {
  return (
    <div className="grid_box right_image">
      <div className="desc_box">
        <p style={{ marginBottom: "53px" }}>
          I have been appointed as a member of the Standing Committee on Blue
          Economy at the Federation of Bangladesh Chambers of Commerce and
          Industry (FBCCI) in 2024. This appointment marks a significant
          milestone in my career and reflects my dedication and commitment to
          advancing the blue economy sector.
        </p>
        <p>
          In summary, my journey in the maritime and shipping industry has been
          deeply influenced by my upbringing, sailing experiences with my
          father, and a passion for the blue ocean. Through my work at Interport
          Group, involvement with organizations like CILT Bangladesh and WiLAT,
          and role as board director at BSAA, I am dedicated to driving the safe
          and sustainable development of our ports and shipping sector.
          Furthermore, my commitment to philanthropy and active engagement in
          social initiatives stems from my deep spiritual belief in the
          importance of giving back to the community, driven by a greater
          purpose to serve the Almighty.
        </p>
      </div>
      <div className="img_box">
        <img src={abtImg9} alt="thumb" className="w_100" />
      </div>
    </div>
  );
}

export default AboutCard9;
