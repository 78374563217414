import { FaArrowLeftLong } from "react-icons/fa6";

function LeftBtn() {
  return (
    <button className="menuSwipeBtnLeft" style={{ zIndex: "999" }}>
      <FaArrowLeftLong />
    </button>
  );
}

export default LeftBtn;
