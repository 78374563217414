import abtImg1 from '../../../assets/images/abt-img-10.jpg'

function AboutCard10() {
  return (
    <div className='grid_box left_image'>
      <div className="img_box">
        <img src={abtImg1} alt="thumb" className='w_100' />
      </div>
      <div className="desc_box">
        <p>
          Interport Group primarily specializes in Marine Claims Consultancy, Marine Survey services, Port Agency and Logistics solutions such as Transportation, Warehousing and Spare Parts Clearance,for ship owners, operators, and marine insurers, whose vessels call in Bangladeshi Ports for trade.
        </p>
        <br />
      </div>

    </div>
  )
}

export default AboutCard10