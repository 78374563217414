"use client";

import React, { useEffect, useState } from "react";
import GalleryItemWrapper from "./GalleryItemWrapper/GalleryItemWrapper";
import GalleryImage from "./GalleryImage";
import GalleryItemInfo from "./GalleryItemInfo";
import { Link } from "react-router-dom";
import axios from "axios";

export default function GalleryItemContainer({ galleryData }) {
  // if (isLoading) return <Loader />;

  return (
    <>
      {galleryData.length > 0 &&
        galleryData?.map((item) => (
          <Link
            to={item?.cardImage ? `/gallery/${item?._id}` : "#"}
            key={item._id.$oid}
            className={`"`}
            style={{ position: "relative" }}
          >
            <GalleryItemWrapper item={item}>
              <GalleryImage item={item} />
              <GalleryItemInfo item={item} />
            </GalleryItemWrapper>
          </Link>
        ))}
    </>
  );
}
