import { FaArrowRightLong } from "react-icons/fa6";

function RightBtn() {
  return (
    <button className="menuSwipeBtnRight" style={{ zIndex: "999" }}>
      <FaArrowRightLong />
    </button>
  );
}

export default RightBtn;
