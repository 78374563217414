import QuoteImg from '../../../../../assets/images/Group-Pic_3.jpg'
import QuoteImg1 from '../../../../../assets/images/Group-Pic_2.jpg'

import './QuoteBanner.css'

function QuoteBanner() {
  return (
    <section className='quote_banner'>
        <img src={QuoteImg} alt="quote" className='w_100' />
        <img src={QuoteImg1} alt="quote" className='w_100' />
    </section>
  )
}

export default QuoteBanner