import React from "react";

export default function GalleryItemWrapper({
  children,
  item,
  setHoveredItemId,
}) {
  return (
    <div
      className="gallery_box"
      style={{ position: "relative", overflow: "hidden" }}
    >
      {children}
    </div>
  );
}
