import abtImg4 from '../../../assets/images/abt-img-3.jpg'

function AboutCard4() {
  return (
    <div className='grid_box left_image'>
      <div className="img_box">
        <img src={abtImg4} alt="thumb" className='w_100' />
      </div>
      <div className="desc_box">
        <p>During my journey, I became a Member of the Chartered Institute of Logistics and Transport (CILT), International. In 2014, I founded the Next Generation (previously known as the Young Professionals Forum) of CILT Bangladesh, a special forum that provides enhanced networking and support opportunities for members in the early stages of their careers in the logistics and transport industry. I led the Next Generation team of Bangladesh, achieving the Runners Up (2nd Position) and Finalist award of the CILT International Young Achiever of the Year Award at the CILT International Convention in Macao in 2017 and CILT Centenary Convention in Manchester, UK in 2019, respectively. In 2018, I was awarded the Membership status of CMILT (Chartered Member) and became the Education Champion of CILT Bangladesh, introducing the CILT International Diploma and CILT International Advanced Diploma in Logistics and Transport in Bangladesh. In 2019, I was featured in the CILT International Centenary Success Stories for my significant contribution to the cause of the institute in Bangladesh. In 2021, I was elected as the Chairman of the Next Generation (NG) of CILT Bangladesh.</p>
      </div>
    </div>
  )
}

export default AboutCard4