import { IMAGE_URL, SLIDERS_API } from "../../../../Utilities/APIs/APIs";
import { useEffect, useState } from "react";
import axios from "axios";

// import sliderImg from '../../../../assets/images/banner-slide-1.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Import own styles
import "./BannerSlider.css";
import LeftBtn from "./NavigationArrows/LeftBtn";
import RightBtn from "./NavigationArrows/RightBtn";

function BannerSlider() {
  const [sliders, setSliders] = useState(null);

  useEffect(() => {
    async function getBannerSlider() {
      const { data } = await axios.get(SLIDERS_API);
      setSliders(data);
    }

    getBannerSlider();
  }, []);

  return (
    <section className="banner_slider_wrapper">
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={{
          prevEl: " .menuSwipeBtnLeft",
          nextEl: " .menuSwipeBtnRight",
        }}
        modules={[Autoplay, Navigation]}
        className="banner_slider"
      >
        {sliders?.map((slider) => (
          <SwiperSlide key={slider._id}>
            <a href={slider.link}>
              <div className="swiper-slide">
                {/* <img className="lg_img" src={sliderImg} alt="thumb" />
                            <img className="sm_img" src={sliderImg} alt="thumb" /> */}
                <img
                  className="lg_img"
                  src={IMAGE_URL + slider?.image}
                  alt="thumb"
                />
                <img
                  className="sm_img"
                  src={IMAGE_URL + slider?.image1}
                  alt="thumb"
                />
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="banner_navigation_area">
        <LeftBtn />
        <RightBtn />
      </div>
    </section>
  );
}

export default BannerSlider;
