import abtImg3 from '../../../assets/images/abt-img-4.jpg'

function AboutCard3() {
  return (
    <div className='grid_box right_image'>
     
      <div className="desc_box">
        <p>Starting as a Junior Claims Executive in the P&I Correspondent division of Interport, 
          I handled various types of maritime claims across Hull & Machinery and P&I Insurance 
          in Chittagong Port and Mongla Port. My responsibilities included managing various marine claims cases related to Cargo Damage, Collision, Contact Damage, Crew Injury, Fixed or Floating Objects (FFOs), Salvage, Stowaways, and more. I also took part in professional training programs abroad in P&I
          Insurance and Loss Prevention organized by the International Group of P&I Clubs. 
          In addition, at the advise of my father, I pursued a graduate-entry Law degree (LLB)
           from the University of London (UOL) – International Programmes.</p>
      </div>
      <div className="img_box">
        <img src={abtImg3} alt="thumb" className='w_100' />
      </div>
    </div>
  )
}

export default AboutCard3