import abtImg1 from '../../../assets/images/abt-img-1.png'

function AboutCard1() {
    return (
        <div className='grid_box left_image'>
            <div className="img_box">
                <img src={abtImg1} alt="thumb" className='w_100' />
            </div>
            <div className="desc_box">
                <p>From my childhood, I have been strongly connected with the maritime sector. My father, a renowned Maritime Expert and Lawyer, graduated from Bangladesh Marine Academy as a Cadet and subsequently from Hong Kong Polytechnic University as a Master Mariner. He sailed onboard various ocean-going ships from Bangladesh Shipping Corporation and Chang Myung Shipping of South Korea as a Captain. I had the opportunity to sail with my father on numerous voyages across Asia and the South Pacific, which developed in me a special connection with the blue ocean.</p>
            </div>
        </div>
    )
}

export default AboutCard1