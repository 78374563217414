"use client";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import GalleryDetailsCard from "../components/GalleryDetailsCard";
import {
  GALLERY_API,
  GALLERY_IMAGE_API,
} from "../../../../Utilities/APIs/APIs";
function GalleryDetails() {
  const [singleGallery, setSingleGallery] = useState();
  const { id } = useParams();

  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(GALLERY_IMAGE_API + id);
      const res = await axios.get(GALLERY_API + id);

      setGalleryData(data);
      setSingleGallery(res?.data);
    }

    fetchData();
  }, [id]);

  return (
    <GalleryDetailsCard gallery={galleryData} singleGallery={singleGallery} />
  );
}

export default GalleryDetails;
