import React from "react";
import { IMAGE_URL } from "../../../../../Utilities/APIs/APIs";

export default function GalleryImage({ item }) {
  return (
    <img
      src={IMAGE_URL + (item?.cardImage || item?.image)}
      className=""
      alt="men"
      style={{ width: "100%", height: "350px", objectFit: "cover" }}
    />
  );
}
