import { backendUrl, spacesBucketUrl } from "../../Constants.js";

export const BASE_URL = backendUrl;
export const IMAGE_URL = spacesBucketUrl;
export const API_URL = BASE_URL + "api/";

export const CUSTOMER_API = API_URL + "customers/";
export const SLIDERS_API = API_URL + "sliders/";
export const ABOUTS_API = API_URL + "abouts/";
export const ABOUT_ME_API = API_URL + "aboutMe/";
export const GALLERY_API = CUSTOMER_API + "gallery/";
export const GALLERY_IMAGE_API = GALLERY_API + "galleryImagesByGalleryId/";
export const BLOGS_API = API_URL + "blogs/";
export const FEATURE_BLOGS_API = API_URL + "featuredBlogs/";
export const VIDEOS_API = API_URL + "videos/";
export const VIDEO_CATEGORIES_API = API_URL + "videoCategories/";
export const FEATURE_VIDEOS_API = API_URL + "featuredVideos/";

export const SOCIAL_LINKS_API = API_URL + "socialLinks/";
