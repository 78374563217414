import abtImg6 from '../../../assets/images/abt-img-6.png'

function AboutCard6() {
    return (
        <div className='grid_box left_image'>
            <div className="img_box">
                <img src={abtImg6} alt="thumb" className='w_100' />
            </div>
            <div className="desc_box">
                <p>In 2020, I was appointed as the Managing Director of Interport Group.
                     The following year,
                      I was elected as the youngest board Director of Bangladesh Shipping Agents Association (BSAA) 
                      for the term 2021-2023 and re-elected again for the term 2023-2025. I am committed to working tirelessly for the betterment of our association and the shipping industry. Contributing to the safe and sustainable development of our ports and shipping sector is my passion.</p>
            </div>
        </div>
    )
}

export default AboutCard6